import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'

import { getRandomFileName } from '../get-random-file-name/get-random-file-name'

export function getAttachmentNameFromHeader(headers: RawAxiosResponseHeaders | AxiosResponseHeaders): string {
  const contentDisposition = headers?.['content-disposition']

  const fileName = contentDisposition
    ?.match(/\bfilename=([^;]*)/)?.[1]
    ?.trim()
    ?.replaceAll('"', '')

  return fileName ?? getRandomFileName()
}
