import { Badge } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { BenefitApplicationStatus } from 'src/shared/types/swagger/api.dto'
import { useBadgeColor } from 'src/shared/ui-kit/badge'

type TableStatusColumnProps = {
  status: BenefitApplicationStatus
  className?: string
}

const BADGE_TEXT_MAPPER: Record<BenefitApplicationStatus, string> = {
  [BenefitApplicationStatus.ACCEPTED]: 'common.status.accepted',
  [BenefitApplicationStatus.DECLINED]: 'common.status.rejected',
  [BenefitApplicationStatus.IN_REVIEW]: 'common.status.in-review',
  [BenefitApplicationStatus.SENT_FOR_REVISION]: 'common.status.sent-for-revision',
}

export function BenefitApplicationStatusBadge(props: TableStatusColumnProps) {
  const { t } = useTranslation()
  const badgeColor = useBadgeColor(props.status)

  return (
    <Badge color={badgeColor} className={props.className}>
      {t(BADGE_TEXT_MAPPER[props.status])}
    </Badge>
  )
}
