import { Badge } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { BenefitStatus } from 'src/shared/types/swagger/api.dto'
import { useBenefitBadgeColor } from 'src/shared/ui-kit/badge'

type TableStatusColumnProps = {
  status: BenefitStatus
}

const BADGE_TEXT_MAPPER: Record<BenefitStatus, string> = {
  [BenefitStatus.ACTIVE]: 'benefits.status.active',
  [BenefitStatus.INACTIVE]: 'benefits.status.inactive',
}

export function BenefitStatusBadge(props: TableStatusColumnProps) {
  const { t } = useTranslation()
  const badgeColor = useBenefitBadgeColor(props.status)

  return <Badge color={badgeColor}>{t(BADGE_TEXT_MAPPER[props.status])}</Badge>
}
