import { Badge, Group, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { YearsAggregations } from 'src/shared/types'
import { Status } from 'src/shared/types/swagger/api.dto'
import { useBadgeColor } from 'src/shared/ui-kit/badge'

// TODO: как будет ясность с текстами, объединить с остальными подобными константами
const BADGE_TEXT_MAPPER: Record<Status, string> = {
  [Status.ACCEPTED]: 'common.status.accepted',
  [Status.DECLINED]: 'common.status.rejected',
  [Status.APPROVED_BY_MANAGER]: 'common.status.accepted',
  [Status.PROCESSING_BY_HR]: 'common.status.in-review',
  [Status.PROCESSING_BY_MANAGER]: 'common.status.in-review',
  [Status.SENT_FOR_REVISION]: 'common.status.in-review',
  [Status.DRAFT]: 'common.status.in-review',
}

type VacationGraphicsLinkListItemProps = Pick<YearsAggregations, 'year' | 'totalStatus'>

export function VacationGraphicsLinkListItem(props: VacationGraphicsLinkListItemProps) {
  const { t } = useTranslation()
  const badgeColor = useBadgeColor(props.totalStatus)

  return (
    <Group component="li" wrap="nowrap" justify="space-between">
      <Text size="sm" fw={600}>
        {props.year}
      </Text>
      <Badge color={badgeColor}>{t(BADGE_TEXT_MAPPER[props.totalStatus])}</Badge>
    </Group>
  )
}
