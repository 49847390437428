import { Badge, Flex, FlexProps, Text, useMantineTheme } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { BenefitApplicationStatus } from 'src/shared/types/swagger/api.dto'
import { useBadgeColor } from 'src/shared/ui-kit/badge'

const BADGE_TEXT_MAPPER: Record<BenefitApplicationStatus, string> = {
  [BenefitApplicationStatus.ACCEPTED]: 'common.status.accepted',
  [BenefitApplicationStatus.DECLINED]: 'common.status.rejected',
  [BenefitApplicationStatus.IN_REVIEW]: 'common.status.in-review',
  [BenefitApplicationStatus.SENT_FOR_REVISION]: 'common.status.sent-for-revision',
}

type BenefitApplicationBadgeProps = {
  status: BenefitApplicationStatus
  stepName?: string
} & FlexProps

export function BenefitApplicationBadge({ status, stepName, ...flexProps }: BenefitApplicationBadgeProps) {
  const { t } = useTranslation()
  const badgeColor = useBadgeColor(status)
  const theme = useMantineTheme()

  return (
    <Flex {...flexProps}>
      <Badge color={badgeColor}>{t(BADGE_TEXT_MAPPER[status])}</Badge>
      {stepName ? (
        <Text size="xs" c={theme.colors['text-grey-3'][0]} truncate>
          {stepName}
        </Text>
      ) : null}
    </Flex>
  )
}
