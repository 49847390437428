import { ComponentProps, ReactElement } from 'react'

import svgSprite from 'src/shared/assets/svg-sprite.svg'

import { ICON_TEST_ID } from '../lib/constants'
import { IconType } from '../lib/types'

export interface IconProps extends ComponentProps<'svg'> {
  name: IconType
  size?: number
}

// TODO: разобраться с проблемами
// https://gitlab.tekmates.pro/astana-group/web_frontend/-/issues/7
export const Icon = ({ name, size = 24, height, width, ...rest }: IconProps): ReactElement => (
  <svg height={height || size} width={width || size} {...rest} data-testid={ICON_TEST_ID}>
    <use xlinkHref={`${svgSprite}#${name}`} />
  </svg>
)
