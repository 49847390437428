import { type FormSchema } from 'src/widgets/application-form/lib/types'

export type FormSchemaQueryKeys = Record<
  keyof Partial<
    Omit<FormSchema, 'employeeSubstitute'> & {
      employeeSubstituteId: string
      applicationId: string
    }
  >,
  string
>

export enum Tabs {
  SENT = 'SENT',
  INCOMING = 'INCOMING',
  ARCHIVED = 'ARCHIVED',
}
