import { Group, Stack, Text, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { ReactElement } from 'react'

import { Icon } from 'src/shared/ui-kit/icon'

interface NamedAvatarProps {
  name: string
  email?: string
  avatar?: string
}

export function NamedAvatar({ name, email, avatar }: NamedAvatarProps): ReactElement {
  const theme = useMantineTheme()
  const matches = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`)

  return (
    <Group align="center" gap="md">
      {!avatar && <Icon name="avatar" size={matches ? 40 : 32} />}

      <Stack gap={0}>
        <Text>{name}</Text>
        {email && (
          <Text size="md" c="text-grey-2">
            {email}
          </Text>
        )}
      </Stack>
    </Group>
  )
}
