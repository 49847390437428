import { createContext } from 'react'

import { UserData } from './types'

export const UserContext = createContext<{
  userData: UserData | null
  experienceInMonths: number | null
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
}>({
  userData: null,
  experienceInMonths: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
})
