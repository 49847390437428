import { useMantineTheme } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { useCallback, useMemo } from 'react'

import { Icon } from '../../icon'
import notificationsClasses from '../ui/notifications.module.css'

import { NotificationAppearance, NotificationData, NotificationVariant } from './types'

export const useNotification = () => {
  const theme = useMantineTheme()
  const NOTIFICATION_MAPPER = useMemo<Record<NotificationVariant, NotificationAppearance>>(
    () => ({
      info: {
        name: 'info_outlined',
        fill: theme.colors.aceent[0],
      },
      success: {
        name: 'checkmark_circle_outlined',
        fill: theme.colors.green[0],
      },
      warning: {
        name: 'warning_outlined',
        fill: theme.colors.orange[0],
      },
      error: {
        name: 'error_outlined',
        fill: theme.colors.red[0],
      },
    }),
    [theme.colors.aceent, theme.colors.green, theme.colors.orange, theme.colors.red],
  )

  const showNotification = useCallback(
    (notificationData: NotificationData) => {
      const iconData = NOTIFICATION_MAPPER[notificationData.variant]

      notifications.show({
        icon: <Icon name={iconData.name} fill={iconData.fill} />,
        classNames: notificationsClasses,
        message: '',
        ...notificationData,
      })
    },
    [NOTIFICATION_MAPPER],
  )

  return {
    showNotification,
  }
}
