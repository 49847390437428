import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LANGUAGES } from '../../constants'
import { Language } from '../../types'

export function useLanguage(): [Language, (value: Language) => void] {
  const { i18n } = useTranslation()

  const changeLanguage = useCallback((lang: Language) => i18n.changeLanguage(lang.value), [i18n])

  const currentLanguage: Language = useMemo(() => LANGUAGES.find((el) => el.value === i18n.language)!, [i18n.language])

  return [currentLanguage, changeLanguage]
}
