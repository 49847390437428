import { useCallback, useMemo } from 'react'

import { useBenefitsApplications, useBenefitTypes } from 'src/shared/api'
import { useUser } from 'src/shared/providers'

import { BenefitTab } from './types'
import { getBenefitsApplicationsParams } from './utils'

export const useIsApplicationStructureAvailable = (typeId?: number) => {
  const benefitTypes = useBenefitTypes()
  const currentBenefitType = benefitTypes.data?.find((benefitType) => benefitType.id === typeId)

  const applicationStructure = currentBenefitType?.applicationStructure

  return {
    isLoading: benefitTypes.isLoading,
    isApplicationStructureAvailable: !!applicationStructure,
  }
}

export const useBenefitsApplicationCounts = (enabled: boolean = true) => {
  const { userData, isSuccess: isUserSuccess } = useUser()

  const sentApplications = useBenefitsApplications(
    getBenefitsApplicationsParams(BenefitTab.SENT, userData),
    enabled ?? isUserSuccess,
  )

  const archiveApplications = useBenefitsApplications(
    getBenefitsApplicationsParams(BenefitTab.ARCHIVE, userData),
    enabled ?? isUserSuccess,
  )

  const incomingApplications = useBenefitsApplications(
    getBenefitsApplicationsParams(BenefitTab.INCOMING, userData),
    enabled ?? isUserSuccess,
  )

  const refetch = useCallback(() => {
    sentApplications.refetch()
    archiveApplications.refetch()
    incomingApplications.refetch()
  }, [archiveApplications, incomingApplications, sentApplications])

  return useMemo(
    () => ({
      refetch,
      counts: {
        [BenefitTab.SENT]: sentApplications.data?.length || 0,
        [BenefitTab.ARCHIVE]: archiveApplications.data?.length || 0,
        [BenefitTab.INCOMING]: incomingApplications.data?.length || 0,
      },
      isLoading: sentApplications.isLoading || archiveApplications.isLoading || incomingApplications.isLoading,
      isError: sentApplications.isError || archiveApplications.isError || incomingApplications.isError,
    }),
    [refetch, archiveApplications, incomingApplications, sentApplications],
  )
}
