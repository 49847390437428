import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import React from 'react'
import ReactDOM from 'react-dom/client'

import '@mantine/core/styles.css'
import 'mantine-datatable/styles.layer.css'
import '@mantine/dates/styles.css'
import '@mantine/notifications/styles.css'

dayjs.extend(customParseFormat)

import App from './app/App'

const ROOT_ID = 'root'

ReactDOM.createRoot(document.getElementById(ROOT_ID)!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
