import dayjs from 'dayjs'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import { initReactI18next } from 'react-i18next'
import { z } from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'
import ruZodTranslation from 'zod-i18n-map/locales/ru/zod.json'

import { LANGUAGES } from '../constants'
import { Locale } from '../types'

import ruJson from './ru.json'

const fallbackLng: Locale = 'ru'

i18n.use(LanguageDetector).use(initReactI18next).use(intervalPlural)

i18n.on('languageChanged', (language) => {
  const locale = LANGUAGES.find((lng) => lng.value === language)?.value ?? fallbackLng
  dayjs.locale(locale)
})

i18n.init({
  fallbackLng,
  returnEmptyString: false,
  detection: {
    order: ['localStorage'],
    lookupLocalStorage: 'language',
  },
  debug: import.meta.env.DEV,
  supportedLngs: [...LANGUAGES.map(({ value }) => value), 'ru'],
  resources: {
    ru: {
      translation: {
        ...ruJson,
        ...ruZodTranslation,
      },
    },
  },
  interpolation: {
    escapeValue: false,
  },
})

z.setErrorMap(
  makeZodI18nMap({
    t: i18n.t,
    ns: ['translation'],
  }),
)

export default i18n
export { z }
