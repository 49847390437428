import { useVacationSchedules } from 'src/shared/api'
import { useUser } from 'src/shared/providers'

export const useVacationSchedule = (enabled: boolean = true) => {
  const { userData, isSuccess, isLoading } = useUser()
  const vacationSchedules = useVacationSchedules({ employeeId: userData?.id }, enabled && isSuccess)

  return {
    data: vacationSchedules.data,
    refetch: vacationSchedules.refetch,
    isLoading: isLoading || vacationSchedules.isLoading,
  }
}
