import { Status, VacationApplicationCategory, VacationApplicationType } from 'src/shared/types/swagger/api.dto'

const STATUS_TRANSLATION_KEYS = ['common', 'status']

export const STATUS_TRANSLATION_KEY_MAPPER: {
  [Status.ACCEPTED]: string
  [Status.DECLINED]: string
  [Status.PROCESSING_BY_HR]: string
  [Status.PROCESSING_BY_MANAGER]: string
  [Status.SENT_FOR_REVISION]: string
} = {
  [Status.ACCEPTED]: [...STATUS_TRANSLATION_KEYS, 'accepted'].join('.'),
  [Status.DECLINED]: [...STATUS_TRANSLATION_KEYS, 'rejected'].join('.'),
  [Status.PROCESSING_BY_HR]: [...STATUS_TRANSLATION_KEYS, 'processing-by-hr'].join('.'),
  [Status.PROCESSING_BY_MANAGER]: [...STATUS_TRANSLATION_KEYS, 'processing-by-manager'].join('.'),
  [Status.SENT_FOR_REVISION]: [...STATUS_TRANSLATION_KEYS, 'under-revision'].join('.'),
}

// TODO: как будет ясность с текстами, объединить с остальными подобными константами
export const STATUS_BADGE_TEXT_MAPPER_CARD: Record<Status, string> = {
  [Status.ACCEPTED]: 'common.status.accepted',
  [Status.DECLINED]: 'common.status.rejected',
  [Status.APPROVED_BY_MANAGER]: 'common.status.accepted',
  [Status.PROCESSING_BY_HR]: 'common.status.in-review',
  [Status.PROCESSING_BY_MANAGER]: 'common.status.in-review',
  [Status.SENT_FOR_REVISION]: 'common.status.under-revision',
  [Status.DRAFT]: 'common.status.in-review',
}

const VACATION_TYPE_TRANSLATION_KEYS = ['vacations', 'applications', 'vacation-type']

export const VACATION_TYPE_TRANSLATION_KEY_MAPPER: Record<VacationApplicationType, string> = {
  ADOPTION_OF_CHILD: [...VACATION_TYPE_TRANSLATION_KEYS, 'adoption-of-child'].join('.'),
  BIRTH_OF_CHILD: [...VACATION_TYPE_TRANSLATION_KEYS, 'birth-of-child'].join('.'),
  CHILD_CARE: [...VACATION_TYPE_TRANSLATION_KEYS, 'child-care'].join('.'),
  DEATH_OF_RELATIVE: [...VACATION_TYPE_TRANSLATION_KEYS, 'death-of-relative'].join('.'),
  ILLNESS_OF_RELATIVE: [...VACATION_TYPE_TRANSLATION_KEYS, 'illness-of-relative'].join('.'),
  MARRIAGE_REGISTRATION: [...VACATION_TYPE_TRANSLATION_KEYS, 'marriage-registration'].join('.'),
  PLANNED: [...VACATION_TYPE_TRANSLATION_KEYS, 'planned'].join('.'),
  UNPLANNED: [...VACATION_TYPE_TRANSLATION_KEYS, 'unplanned'].join('.'),
}

const VACATION_CATEGORY_TRANSLATION_KEYS = ['vacations', 'applications', 'vacation-category']

export const VACATION_CATEGORY_TRANSLATION_KEY_MAPPER: Record<VacationApplicationCategory, string> = {
  LABOR_LEAVE: [...VACATION_CATEGORY_TRANSLATION_KEYS, 'labor-leave'].join('.'),
  PARENTAL_LEAVE: [...VACATION_CATEGORY_TRANSLATION_KEYS, 'parental-leave'].join('.'),
  SOCIAL_LEAVE: [...VACATION_CATEGORY_TRANSLATION_KEYS, 'social-leave'].join('.'),
  STUDY_LEAVE: [...VACATION_CATEGORY_TRANSLATION_KEYS, 'study-leave'].join('.'),
  UNPAID: [...VACATION_CATEGORY_TRANSLATION_KEYS, 'unpaid'].join('.'),
}
