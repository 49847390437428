import { MantineProvider as Provider } from '@mantine/core'
import { PropsWithChildren, ReactNode } from 'react'

import { theme } from './theme'

export function MantineProvider({ children }: PropsWithChildren): ReactNode {
  return (
    <Provider
      theme={theme}
      cssVariablesResolver={(theme) => ({
        variables: {
          '--mantine-other-input-md': theme.other.input.md,
          '--mantine-other-input-lg': theme.other.input.lg,
          '--mantine-other-notification-close-time': theme.other.notification.closeTime,
        },
        light: {
          '--mantine-other-input-md': theme.other.input.md,
          '--mantine-other-input-lg': theme.other.input.lg,
          '--mantine-other-notification-close-time': theme.other.notification.closeTime,
        },
        dark: {
          '--mantine-other-input-md': theme.other.input.md,
          '--mantine-other-input-lg': theme.other.input.lg,
          '--mantine-other-notification-close-time': theme.other.notification.closeTime,
        },
      })}
    >
      {children}
    </Provider>
  )
}
