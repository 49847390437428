import { Notifications } from '@mantine/notifications'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router-dom'

import { MantineDatesProvider, MantineProvider, queryClient, RoleProvider, UserProvider } from 'src/shared/providers'

import { router } from './router/router-config'

import 'src/shared/i18n/i18next.config'
import './style.css'

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RoleProvider>
        <UserProvider>
          <MantineProvider>
            <Notifications />
            <MantineDatesProvider>
              <RouterProvider router={router} />
              <ReactQueryDevtools />
            </MantineDatesProvider>
          </MantineProvider>
        </UserProvider>
      </RoleProvider>
    </QueryClientProvider>
  )
}

export default App
