import { MantineTheme, NumberFormatter, Stack, Text, TextProps, useMantineTheme } from '@mantine/core'
import dayjs from 'dayjs'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ReadonlyFieldProps, ReadonlyFieldVariant } from '../lib/types'
import { getBooleanText } from '../lib/utils'

const getLabelStyles = (theme: MantineTheme): Record<ReadonlyFieldVariant, TextProps> => ({
  primary: {
    size: 'sm',
    c: theme.colors['text-grey-3'][0],
  },
  secondary: {
    size: 'md',
    fw: 700,
  },
})

export const ReadonlyField = ({
  label,
  variant = 'primary',
  type = 'string',
  value,
  renderValue,
}: ReadonlyFieldProps): ReactElement => {
  const { t } = useTranslation()
  const theme = useMantineTheme()

  const labelStyles = getLabelStyles(theme)[variant]
  const gapSize = variant === 'primary' ? 2 : 4
  const textValueSize = variant === 'primary' ? 'lg' : 'md'

  const getReadonlyValue = (): string | number => {
    switch (type) {
      case 'integer':
      case 'decimal': {
        return Number(value)
      }

      case 'string': {
        return value?.toString().trim() || '-'
      }

      case 'boolean':
        return t(getBooleanText(value as string | boolean))

      case 'date': {
        const newValue = dayjs(value?.toString(), ['YYYY-MM-DD', 'DD-MM-YYYY']).toDate()
        return dayjs(newValue).format('DD.MM.YYYY')
      }

      default:
        return '-'
    }
  }

  return (
    <Stack gap={gapSize}>
      <Text {...labelStyles}>{label}</Text>

      {renderValue ? (
        renderValue()
      ) : type === 'decimal' ? (
        <Text
          size={textValueSize}
          component={NumberFormatter}
          value={getReadonlyValue()}
          thousandSeparator=" "
          suffix=" ₸"
        />
      ) : (
        <Text size={textValueSize}>{getReadonlyValue()}</Text>
      )}
    </Stack>
  )
}
