import { fetchEventSource } from '@microsoft/fetch-event-source'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useApplicationsData } from 'src/widgets/application/lib/hooks'
import { useBenefitsApplicationCounts } from 'src/entities/benefit'
import { useVacationSchedule } from 'src/entities/vacation'
import { URI } from 'src/shared/api'
import { STORAGE_KEYS } from 'src/shared/constants'
import { useUser } from 'src/shared/providers'
import { Status } from 'src/shared/types/swagger/api.dto'
import { useNotification } from 'src/shared/ui-kit'
import { camelizeKeys, parseJSON } from 'src/shared/utils'

import { getVariantByStatus } from './utils'

const API_URL = import.meta.env.VITE_BASE_URI + URI.events

interface ServerSentMessage {
  timestamp: string
  employeeId: number
  applicationId: number
  status: Status
  eventType: 'BENEFIT_APPLICATION' | 'VACATION_APPLICATION' | 'VACATION_SCHEDULE'
}

const STATUS_NOTIFICATION_SUPPORT_LIST = [
  Status.ACCEPTED,
  Status.APPROVED_BY_MANAGER,
  Status.DECLINED,
  Status.SENT_FOR_REVISION,
  Status.PROCESSING_BY_HR,
] as readonly Status[]

const RECONNECTION_MAX_ATTEMPTS = 3

export const useApplicationNotifications = () => {
  const { t } = useTranslation()
  const { showNotification } = useNotification()
  const { userData } = useUser()

  const benefitApplications = useBenefitsApplicationCounts(false)
  const vacationSchedules = useVacationSchedule(false)
  const vacationApplications = useApplicationsData()

  const eventTypeMap: Record<ServerSentMessage['eventType'], () => void> = useMemo(
    () => ({
      BENEFIT_APPLICATION: () => benefitApplications.refetch(),
      VACATION_APPLICATION: () => vacationApplications.refetch(),
      VACATION_SCHEDULE: () => vacationSchedules.refetch(),
    }),
    [benefitApplications, vacationApplications, vacationSchedules],
  )

  const getTitleByStatus = (message: ServerSentMessage): string => {
    switch (message.eventType) {
      case 'BENEFIT_APPLICATION':
        switch (message.status) {
          case Status.ACCEPTED:
          case Status.APPROVED_BY_MANAGER:
            return t('benefits.application-approved')
          case Status.DECLINED:
            return t('benefits.application-declined')
          case Status.SENT_FOR_REVISION:
            return t('benefits.application-sent-for-revision')
          default:
            return ''
        }

      case 'VACATION_APPLICATION':
        switch (message.status) {
          case Status.ACCEPTED:
            return t('vacations.applications.notifications.approve-by-hr')
          case Status.APPROVED_BY_MANAGER:
            return t('vacations.applications.notifications.approve-by-head')
          case Status.DECLINED:
            return t('vacations.applications.notifications.declined-by-hr')
          case Status.SENT_FOR_REVISION:
            return t('vacations.applications.notifications.sent-for-revision-by-hr')
          case Status.PROCESSING_BY_HR:
            return t('vacations.applications.notifications.processing-by-hr')
          default:
            return ''
        }

      case 'VACATION_SCHEDULE':
        switch (message.status) {
          case Status.PROCESSING_BY_HR:
            return t('vacations.vacation-agreed-by-manager')
          case Status.ACCEPTED:
            return t('vacations.vacation-fully-agreed')
          case Status.SENT_FOR_REVISION:
            return t('vacations.vacation-sent-for-revision')
          default:
            return ''
        }

      default:
        return ''
    }
  }

  useEffect(() => {
    let reconnectionAttempts = 0

    const abortController = new AbortController()
    const { signal } = abortController

    const initServerSentEvents = async () => {
      const token = localStorage.getItem(STORAGE_KEYS.accessToken)

      try {
        await fetchEventSource(API_URL, {
          method: 'GET',
          headers: {
            Accept: 'text/event-stream',
            Authorization: `Bearer ${token}`,
          },
          signal,
          openWhenHidden: true,
          async onopen(response) {
            console.log('onopen')
            const contentType = response.headers.get('content-type')
            if (!!contentType && contentType.indexOf('application/json') >= 0) {
              throw await response.json()
            } else {
              console.log('success')
            }
          },
          onmessage(event) {
            console.log('before converting | ', event)

            const message = camelizeKeys(parseJSON<ServerSentMessage>(event.data))
            if (!message) {
              return
            }

            console.log('after converting |', message)
            if (!STATUS_NOTIFICATION_SUPPORT_LIST.includes(message?.status)) {
              console.log(`Benefits: ${message.status} is not supported`)
              return
            }

            eventTypeMap[message?.eventType]?.()
            showNotification({
              variant: getVariantByStatus(message.status),
              title: getTitleByStatus(message),
            })
          },
          onclose() {
            console.log('onclose')
          },
          onerror(error) {
            throw error
          },
        })
      } catch (error) {
        reconnectionAttempts++
        console.log('retrying')
        if (reconnectionAttempts <= RECONNECTION_MAX_ATTEMPTS) {
          initServerSentEvents()
        }
      }
    }

    if (userData?.role) {
      console.log('initialize')
      initServerSentEvents()
    }

    return () => {
      console.log('abort')
      abortController.abort()
    }
    // eslint-disable-next-line
  }, [userData?.role])
}
