import { Navigate, Outlet, useParams } from 'react-router-dom'

import { PATHS } from 'src/shared/constants'

export function RootHrApplicationDetailPage() {
  const { applicationId } = useParams<'applicationId'>()

  if (!applicationId) {
    return <Navigate to={PATHS.hr.index} replace />
  }

  return <Outlet />
}
