import 'dayjs/locale/ru'

import { Language } from '../types'

export const LANGUAGES: Language[] = [
  {
    value: 'ru',
    label: 'Русский',
  },
]
