import { EmployeeApplicationTableColumns, HeadApplicationsTableColumns } from './types'

const TABLE_TRANSLATION_PATHS = ['vacations', 'applications', 'table']

const TABLE_COLUMNS_TRANSLATION_PATHS = [...TABLE_TRANSLATION_PATHS, 'columns']

export const EMPLOYEE_TABLE_COLUMNS: Record<keyof EmployeeApplicationTableColumns, string> = {
  vacationCategory: [...TABLE_COLUMNS_TRANSLATION_PATHS, 'vacationCategory'].join('.'),
  vacationType: [...TABLE_COLUMNS_TRANSLATION_PATHS, 'vacationType'].join('.'),
  vacationPeriod: [...TABLE_COLUMNS_TRANSLATION_PATHS, 'vacationPeriod'].join('.'),
  vacationDaysQuantity: [...TABLE_COLUMNS_TRANSLATION_PATHS, 'vacationDays'].join('.'),
  created: [...TABLE_COLUMNS_TRANSLATION_PATHS, 'created'].join('.'),
  status: [...TABLE_COLUMNS_TRANSLATION_PATHS, 'status'].join('.'),
}

export const HEAD_TABLE_COLUMNS: Record<keyof HeadApplicationsTableColumns, string> = {
  from: [...TABLE_COLUMNS_TRANSLATION_PATHS, 'from'].join('.'),
  position: [...TABLE_COLUMNS_TRANSLATION_PATHS, 'position'].join('.'),
  ...EMPLOYEE_TABLE_COLUMNS,
}

export const HR_TABLE_COLUMNS = HEAD_TABLE_COLUMNS

export const ACTIVE_TAB_QUERY_KEY = 'activeTabQueryKey'

export const STATUS_COLUMN_WIDTH = 152
