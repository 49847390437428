import { Box, Combobox, Stack, TextInputProps, useCombobox, useMantineTheme } from '@mantine/core'
import { forwardRef } from 'react'

import { AllowedValues } from 'src/shared/types/swagger/api.dto'
import { BaseInputWithFloatedLabel, Icon, ReadonlyFieldProps } from 'src/shared/ui-kit'

type BenefitDynamicFieldSelectProps = Pick<TextInputProps, 'required' | 'size' | 'error' | 'disabled' | 'label'> &
  Pick<ReadonlyFieldProps, 'variant'> & {
    value?: string | null
    onChange?: (value: string | null) => void
    options?: AllowedValues[]
  }

export const BenefitDynamicFieldSelect = forwardRef<HTMLInputElement, BenefitDynamicFieldSelectProps>(
  function BenefitDynamicFieldSelect(props, ref) {
    const combobox = useCombobox()
    const theme = useMantineTheme()

    const inputValue = props.options?.find((option) => option.value === props.value)

    const options = props.options?.map(({ value }) => (
      <Combobox.Option value={value} key={value}>
        {value}
      </Combobox.Option>
    ))

    const handleOptionSubmit = (value: string): void => {
      props.onChange?.(value ?? null)
      combobox.closeDropdown()
    }

    return (
      <Combobox
        store={combobox}
        onOptionSubmit={handleOptionSubmit}
        withinPortal={false}
        styles={{
          dropdown: {
            top: props.size === 'lg' ? 24 : 0,
            right: 0,
            transform: `translateY(${theme.other.input.md})`,
          },
        }}
      >
        <Box pos="relative">
          <Combobox.Target>
            <BaseInputWithFloatedLabel
              ref={ref}
              size={props.size}
              required={props.required}
              disabled={props.disabled}
              error={props.error}
              label={props.label}
              value={inputValue?.value}
              rightSection={
                <Icon name={`chevron_${combobox.dropdownOpened ? 'up' : 'down'}`} fill={theme.colors['light'][6]} />
              }
              onClick={() => combobox.toggleDropdown()}
            />
          </Combobox.Target>

          <Stack component={Combobox.Dropdown}>
            <Combobox.Options>{options}</Combobox.Options>
          </Stack>
        </Box>
      </Combobox>
    )
  },
)
