import { InputBase, InputBaseProps } from '@mantine/core'
import { ComponentProps, forwardRef, useState } from 'react'

import classes from './base-input-with-floated-label.module.css'

type BaseInputWithFloatedLabelProps = Pick<
  InputBaseProps,
  'label' | 'rightSection' | 'size' | 'required' | 'error' | 'disabled' | 'w'
> &
  Pick<ComponentProps<'input'>, 'onClick' | 'value'>

export const BaseInputWithFloatedLabel = forwardRef<HTMLInputElement, BaseInputWithFloatedLabelProps>(function Input(
  { size = 'md', ...rest },
  ref,
) {
  const [focused, setFocused] = useState<boolean>(false)
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false)

  const floating = focused || !!rest.value || undefined

  return (
    <InputBase
      ref={ref}
      pointer
      // TODO: убрать, когда будем использовать валидацию библиотеки
      // заменил вместо component="button", так как для него не работает нативная html5 валидация
      component="input"
      readOnly={isReadOnly}
      onFocus={() => {
        setFocused(true)
      }}
      onBlur={() => {
        setFocused(false)
        // TODO: убрать, когда будем использовать валидацию библиотеки
        // необходимо, чтобы сработала нативная html5 валидация
        // при пропсе readonly она не работает
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/required
        setIsReadOnly(false)
      }}
      onKeyDownCapture={() => setIsReadOnly(true)}
      onKeyUpCapture={() => setIsReadOnly(true)}
      rightSectionPointerEvents="none"
      classNames={{
        label: classes.label,
        root: classes.root,
        input: classes.input,
        error: classes.error,
      }}
      wrapperProps={{
        'data-floating': floating,
        'data-size': size,
      }}
      {...rest}
    />
  )
})
