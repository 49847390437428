import {
  AllowedValues,
  ApplicationField,
  ApplicationStructureOutput,
  BenefitApplicationCreateInput,
} from 'src/shared/types/swagger/api.dto'

export enum BenefitTab {
  LIST = 'list',
  SENT = 'sent',
  INCOMING = 'incoming',
  ARCHIVE = 'archive',
}

export type BenefitTabKey = keyof typeof BenefitTab

export type ApplicationStructureWithValue = ApplicationField & {
  value: AllowedValues | string | number | null | Date | boolean | ApplicationStructureOutput[]
}

export type BenefitApplyFormSchema = Omit<BenefitApplicationCreateInput, 'applicationStructure'> & {
  applicationStructure: ApplicationStructureWithValue[]
}

export enum BenefitTypeName {
  MEDICAL_SERVICE = 'Медицинское обслуживание',
  DISCOUNTS_ON_CAR = 'Скидки на автомобили',
  DISCOUNTS_ON_CAR_SERVICE = 'Скидки на обслуживание авто и запчасти',
  MARKETING_ACTION = 'Маркетинговые акции',
  FITNESS_COMPENSATION = 'Компенсация фитнеса',
  EDUCATION_COMPENSATION = 'Компенсация обучения',
  FINANCIAL_ASSISTANCE = 'Материальная помощь',
}

export enum BenefitComponentName {
  EMPLOYEE_EXPERIENCE = 'Стаж сотрудника',
  DISCOUNT = 'Процент скидки',
  RELATIVE_UIN = 'ИИН',
  RELATIVE_SWITCHER = 'Для родственника',
}

export type BenefitTypeWithAddButton = BenefitTypeName.MEDICAL_SERVICE | BenefitTypeName.DISCOUNTS_ON_CAR_SERVICE

export type BenefitComponentsWithMask = BenefitComponentName.RELATIVE_UIN

export const isBenefitTypeWithAddButton = (payload: unknown): payload is BenefitTypeWithAddButton => {
  return [BenefitTypeName.MEDICAL_SERVICE, BenefitTypeName.DISCOUNTS_ON_CAR_SERVICE].includes(
    payload as BenefitTypeWithAddButton,
  )
}
