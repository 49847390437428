import { Box, Combobox, Loader, Stack, TextInputProps, useCombobox, useMantineTheme } from '@mantine/core'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useBenefitTypes } from 'src/shared/api'
import { BenefitType } from 'src/shared/types/swagger/api.dto'
import {
  BaseInputWithFloatedLabel,
  getReadonlyArrayValues,
  Icon,
  ReadonlyField,
  ReadonlyFieldProps,
} from 'src/shared/ui-kit'

type BenefitTypeSelectProps = Pick<TextInputProps, 'required' | 'size' | 'error' | 'disabled' | 'label'> &
  Pick<ReadonlyFieldProps, 'variant'> & {
    value?: BenefitType['id'] | null
    onChange?: (value: BenefitType['id'] | null) => void
    readOnly?: boolean
  }

export const BenefitTypeSelect = forwardRef<HTMLInputElement, BenefitTypeSelectProps>(
  function BenefitTypeSelect(props, ref) {
    const { t } = useTranslation()
    const combobox = useCombobox()
    const theme = useMantineTheme()

    const { data = [], isLoading } = useBenefitTypes()

    const options = data.map(({ name, id }) => (
      // проблемы с типизацией в библиотеке, все отрабатывает корректно, если мы храним объект в value
      // @ts-ignore
      <Combobox.Option value={id} key={id}>
        {name}
      </Combobox.Option>
    ))

    const inputValue = data.find(({ id }) => id === props.value)?.name ?? ''

    const handleOptionSubmit = (value: unknown): void => {
      const benefitTypeId = value as BenefitType['id'] | null

      props.onChange?.(benefitTypeId)
      combobox.closeDropdown()
    }

    if (props.readOnly) {
      return (
        <ReadonlyField
          label={props.label as string}
          variant={props.variant}
          value={getReadonlyArrayValues(data, props.value ? [props.value] : [])}
        />
      )
    }

    return (
      <Combobox
        store={combobox}
        onOptionSubmit={handleOptionSubmit}
        withinPortal={false}
        styles={{
          dropdown: {
            top: props.size === 'lg' ? 24 : 0,
            right: 0,
            transform: `translateY(${theme.other.input.md})`,
          },
        }}
      >
        <Box pos="relative">
          <Combobox.Target>
            <BaseInputWithFloatedLabel
              ref={ref}
              size={props.size}
              required={props.required}
              disabled={props.disabled}
              error={props.error}
              label={props.label}
              value={inputValue}
              rightSection={
                <Icon name={`chevron_${combobox.dropdownOpened ? 'up' : 'down'}`} fill={theme.colors['light'][6]} />
              }
              onClick={() => combobox.toggleDropdown()}
            />
          </Combobox.Target>

          <Stack component={Combobox.Dropdown}>
            {isLoading ? (
              <Loader />
            ) : (
              <Combobox.Options>
                {options.length > 0 ? options : <Combobox.Empty>{t('common.no-items-were-found')}</Combobox.Empty>}
              </Combobox.Options>
            )}
          </Stack>
        </Box>
      </Combobox>
    )
  },
)
