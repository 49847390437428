export function snakeCase(str: string): string {
  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2')
    .toLowerCase()
}

export function kebabCase(str: string): string {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
    .toLowerCase()
}

export function camelCase(str: string): string {
  return str
    .replace(/([-_][a-z])/gi, (group) => group.toUpperCase().replace('-', '').replace('_', ''))
    .replace(/^./, (firstChar) => firstChar.toLowerCase())
}

export function decamelizeKeys<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map((v) => decamelizeKeys(v)) as unknown as T
  }

  if (obj !== null && typeof obj === 'object' && !Array.isArray(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => {
        const decamelizedKey = snakeCase(key)
        ;(result as Record<string, unknown>)[decamelizedKey] = decamelizeKeys((obj as Record<string, unknown>)[key])
        return result
      },
      {} as Record<string, unknown>,
    ) as T
  }
  return obj
}

export function camelizeKeys<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v)) as unknown as T
  }

  if (obj !== null && typeof obj === 'object' && !Array.isArray(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => {
        const camelizedKey = camelCase(key)
        ;(result as Record<string, unknown>)[camelizedKey] = camelizeKeys((obj as Record<string, unknown>)[key])
        return result
      },
      {} as Record<string, unknown>,
    ) as T
  }

  return obj
}
