import { BenefitStatus } from 'src/shared/types/swagger/api.dto'

export const STATUS_TRANSLATION_KEY_MAPPER = {
  [BenefitStatus.ACTIVE]: 'benefits.status.active',
  [BenefitStatus.INACTIVE]: 'benefits.status.inactive',
}

export const BENEFIT_AVAILABILITY_KEY_MAPPER: Map<boolean, string> = new Map([
  [true, 'benefits.availability.available'],
  [false, 'benefits.availability.unavailable'],
])
