import { Status } from 'src/shared/types/swagger/api.dto'

export const TRANSLATION_KEYS_PATH = ['vacations', 'applications', 'status']

// https://tekmates.atlassian.net/wiki/spaces/HRM/pages/299728904/-
export const BADGE_TEXT_MAPPER_MAPPER: Record<Status, string> = {
  [Status.PROCESSING_BY_MANAGER]: [...TRANSLATION_KEYS_PATH, 'processing'].join('.'),
  [Status.PROCESSING_BY_HR]: [...TRANSLATION_KEYS_PATH, 'processing'].join('.'),
  [Status.APPROVED_BY_MANAGER]: [...TRANSLATION_KEYS_PATH, 'processing'].join('.'),
  [Status.SENT_FOR_REVISION]: [...TRANSLATION_KEYS_PATH, 'sent-for-revision'].join('.'),
  [Status.ACCEPTED]: [...TRANSLATION_KEYS_PATH, 'accepted'].join('.'),
  [Status.DECLINED]: [...TRANSLATION_KEYS_PATH, 'rejected'].join('.'),

  // не будет
  [Status.DRAFT]: Status.DRAFT,
}
