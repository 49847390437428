import { useContext } from 'react'

import { RoleContext } from '../model/context'

import { RoleContextType } from './types'

export const useRole = (): RoleContextType => {
  const context = useContext(RoleContext)
  if (!context) {
    throw new Error('useRole must be used within a RoleProvider')
  }
  return context
}
