import { PropsWithChildren, ReactNode, useEffect, useState } from 'react'

import { STORAGE_KEYS } from 'src/shared/constants'
import { Role } from 'src/shared/types/swagger/api.dto'

import { RoleContext } from '../model/context'

type RoleProviderProps = {
  initialRole?: Role
}

export function RoleProvider({
  children,
  initialRole = Role.Employee,
}: PropsWithChildren<RoleProviderProps>): ReactNode {
  const [role, setRole] = useState<Role>(() => {
    const savedRole = localStorage.getItem(STORAGE_KEYS.userRole) as Role | null
    return savedRole || initialRole
  })

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.userRole, role)
  }, [role])

  return <RoleContext.Provider value={{ role, setRole }}>{children}</RoleContext.Provider>
}
