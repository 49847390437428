import dayjs from 'dayjs'

import { UserData } from 'src/shared/providers/user-provider/types'
import { BenefitsApplicationsParams } from 'src/shared/types'
import {
  ApplicationStructureOutput,
  ApprovalProcessCreate,
  Benefit,
  BenefitApplicationStatus,
  BenefitCreate,
} from 'src/shared/types/swagger/api.dto'

import { ApplicationStructureWithValue, BenefitTab } from './types'

export const serializeBenefitToBenefitCreate = (benefit: Benefit): BenefitCreate => ({
  approvalProcess:
    benefit.approvalProcess?.map(({ id: _, ...approvalProcess }): ApprovalProcessCreate => approvalProcess) ?? [],
  benefitTypeFields: benefit.benefitTypeFields,
  description: benefit.description,
  instruction: benefit.instruction,
  name: benefit.name,
  photoId: benefit.photoId,
  requirements: benefit.requirements,
  status: benefit.status,
  typeId: benefit.typeId,
})

const getApplicationStructureOutputValue = (
  application: ApplicationStructureWithValue,
): ApplicationStructureOutput[] | string => {
  switch (application.type) {
    case 'string': {
      return application.value?.toString() ?? ''
    }

    case 'decimal':
    case 'integer':
    case 'boolean':
      return application.value?.toString() ?? ''

    case 'date': {
      return dayjs(application.value as Date).format('YYYY-MM-DD')
    }

    case 'object':
      if (!Array.isArray(application.value)) {
        return ''
      }

      return (
        application.fields?.map((field, index) => {
          const payload: ApplicationStructureWithValue = {
            ...field,
            value: Array.isArray(application.value) ? application.value[index].value : application.value,
          }

          return serializeApplicationStructureOutput(payload)
        }) ?? ''
      )

    default:
      return ''
  }
}

export const serializeApplicationStructureOutput = (
  application: ApplicationStructureWithValue,
): ApplicationStructureOutput => ({
  name: application.name,
  value: getApplicationStructureOutputValue(application),
})

export const getBenefitsApplicationsParams = (
  activeTab?: string,
  userData?: UserData | null,
): BenefitsApplicationsParams => {
  switch (activeTab) {
    case BenefitTab.SENT:
      return {
        statusIn: [BenefitApplicationStatus.IN_REVIEW, BenefitApplicationStatus.SENT_FOR_REVISION],
        authorId: userData?.id,
      }
    case BenefitTab.ARCHIVE:
      return {
        statusIn: [BenefitApplicationStatus.ACCEPTED, BenefitApplicationStatus.DECLINED],
        authorId: userData?.id,
      }
    case BenefitTab.INCOMING:
      return {
        reviewerPositionId: userData?.positionId,
      }
    default:
      return {}
  }
}
