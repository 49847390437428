export type FeatureFlags = 'role-switcher'

interface FeatureFlagHookReturnType {
  isEnabled: boolean
  isDisabled: boolean
}

const FEATURE_FLAGS_STATE: Record<FeatureFlags, 'enabled' | 'disabled'> = {
  'role-switcher': import.meta.env.VITE_FEATURE_FLAG_ROLE_SWITCHER,
}

export const useFeatureFlag = (feature: FeatureFlags): FeatureFlagHookReturnType => {
  const isEnabled = FEATURE_FLAGS_STATE[feature] === 'enabled'

  return {
    isEnabled,
    isDisabled: !isEnabled,
  }
}
