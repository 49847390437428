import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ACTIVE_TAB_QUERY_KEY } from 'src/features/applications-table'
import { useApplications } from 'src/shared/api'
import { useUser } from 'src/shared/providers'
import { ApplicationFilters } from 'src/shared/types'
import { Role, Status } from 'src/shared/types/swagger/api.dto'

import { Tabs } from './types'

export function useApplicationsData(withTabs?: boolean) {
  const [params] = useSearchParams()

  const [activeTab, setActiveTab] = useState<Tabs | null>(() =>
    withTabs ? ((params.get(ACTIVE_TAB_QUERY_KEY) as Tabs) ?? Tabs.SENT) : null,
  )

  const { userData, isLoading: isUserLoading } = useUser()

  const filters: Partial<ApplicationFilters> | undefined = useMemo(() => {
    switch (userData?.role) {
      case Role.Employee:
        return {
          authorId: userData?.id,
        }
      case Role.Manager:
        switch (activeTab) {
          case Tabs.SENT:
            return {
              authorId: userData?.id,
            }
          case Tabs.INCOMING:
            return {
              managerId: userData?.id,
              statusIn: [Status.PROCESSING_BY_MANAGER],
            }
          case Tabs.ARCHIVED:
            return {
              managerId: userData?.id,
              statusIn: [Status.ACCEPTED, Status.DECLINED],
            }
          default:
            return undefined
        }
      case Role.Hr:
        switch (activeTab) {
          case Tabs.SENT:
            return {
              authorId: userData?.id,
            }
          case Tabs.INCOMING:
            return {
              managerId: userData?.id,
              statusIn: [Status.APPROVED_BY_MANAGER, Status.PROCESSING_BY_HR, Status.PROCESSING_BY_MANAGER],
            }
          case Tabs.ARCHIVED:
            return {
              managerId: userData?.id,
              statusIn: [Status.ACCEPTED, Status.DECLINED],
            }
          default:
            return undefined
        }
      default:
        return undefined
    }
  }, [activeTab, userData?.id, userData?.role])

  const { isLoading: isApplicationsLoading, refetch, data: applicationsData = [] } = useApplications(filters)

  return {
    isLoading: isUserLoading || isApplicationsLoading,
    applicationsData,
    userRole: userData?.role,
    activeTab,
    setActiveTab,
    refetch,
  }
}
