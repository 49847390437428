import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import { Role } from 'src/shared/types/swagger/api.dto'

import { useRole } from '../lib/hooks'

type RoleGuardProps = {
  roles: Role[]
  fallback: ReactNode
}

export function RoleGuard({ roles, fallback }: RoleGuardProps): ReactNode {
  const { role } = useRole()

  if (roles.includes(role)) {
    return <Outlet />
  }

  return <>{fallback}</>
}
