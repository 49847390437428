import dayjs from 'dayjs'
import { PropsWithChildren, useMemo } from 'react'

import { useEmployees, useGetMe } from 'src/shared/api'

import { UserContext } from './context'
import { UserData } from './types'

export function UserProvider(props: PropsWithChildren) {
  const meDetail = useGetMe()
  const userDetail = useEmployees({ employeeId: meDetail.data?.id }, !!meDetail.data?.id)

  const userData = useMemo((): UserData | null => {
    if (meDetail.isSuccess && userDetail.isSuccess) {
      return {
        ...userDetail.data.at(0),
        role: meDetail.data.role,
      } as UserData
    }

    return null
  }, [meDetail.isSuccess, userDetail.isSuccess, userDetail.data, meDetail.data])

  const experienceInMonths = useMemo((): number | null => {
    if (userData) {
      const employmentDate = dayjs(userData.employmentDate)
      return dayjs(new Date()).diff(employmentDate, 'months')
    }

    return null
  }, [userData])

  return (
    <UserContext.Provider
      value={{
        userData,
        experienceInMonths,
        isLoading: meDetail.isLoading || userDetail.isLoading,
        isSuccess: userDetail.isSuccess,
        isError: userDetail.isError,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
