import { AppShell, Container, Flex, Select, useMantineTheme } from '@mantine/core'
import { useMediaQuery, useNetwork } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { PropsWithChildren, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Outlet } from 'react-router-dom'

import { useApplicationNotifications } from 'src/features/application-notifications'
import { NamedAvatar } from 'src/entities/user'
import { PATHS } from 'src/shared/constants'
import { useFeatureFlag } from 'src/shared/hooks'
import { useRole } from 'src/shared/providers'
import { Role } from 'src/shared/types/swagger/api.dto'
import { useNotification } from 'src/shared/ui-kit'
import { Icon } from 'src/shared/ui-kit/icon'

export const Layout = ({
  children,
  withErrorNotification,
}: PropsWithChildren & { withErrorNotification?: boolean }): ReactElement => {
  const { isEnabled: isRoleSwitcherEnabled } = useFeatureFlag('role-switcher')
  const { showNotification } = useNotification()
  const { t } = useTranslation()
  const { online } = useNetwork()
  const theme = useMantineTheme()
  const matches = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`)

  useApplicationNotifications()

  const roleList = Object.values(Role)

  const [user] = useState({ name: 'Мерманова Д.Р.', email: 'mermanova97@gmail.com' })
  const { role, setRole } = useRole()

  useEffect(() => {
    if (withErrorNotification) {
      showNotification({ variant: 'error', title: t('error.default') })
    }
  }, [showNotification, t, withErrorNotification])

  useEffect(() => {
    if (!online) {
      // TODO: подправить анимацию, чтобы при autoClose = false она не проигрывалась
      showNotification({ variant: 'error', title: t('common.no-internet'), autoClose: false })
    } else {
      notifications.clean()
    }
  }, [online, showNotification, t])

  return (
    <AppShell>
      <AppShell.Header>
        <Container bg="white" size="xl">
          <Flex justify="space-between" align="center">
            <Link to={PATHS.index}>
              <Icon name="logo" width={96} height={23} />
            </Link>

            {isRoleSwitcherEnabled && (
              <Select
                placeholder="Role"
                data={roleList.filter((el) => el !== Role.Hrd)}
                value={role}
                onChange={(role) => setRole(role as Role)}
              />
            )}

            {matches ? (
              <NamedAvatar name={user.name} email={user.email}></NamedAvatar>
            ) : (
              <Icon name="avatar" size={32} />
            )}
          </Flex>
        </Container>
      </AppShell.Header>

      <AppShell.Main bg="background-grey-1">
        <Container size="xl" my="lg">
          {children ?? <Outlet />}
        </Container>
      </AppShell.Main>
    </AppShell>
  )
}
